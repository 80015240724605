<template>
  <div style="width: 246px; padding: 16px">
    <div
      style="
        border-radius: 16px;
        overflow: hidden;
        background: white;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      "
    >
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
      <div style="height: 100%; background: white">hello</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>