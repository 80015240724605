<template>
  <div class="container" style="cursor: grab">
    <panZoom id="g4">
      <div style="display: flex; gap: 32px">
        <div>
          <p>Hello World</p>
          <div style="background: white; width: 600px">
            <lottie-vue-player
              style="background: red"
              :src="`https://assets9.lottiefiles.com/packages/lf20_cqdzv4dr.json`"
            >
            </lottie-vue-player>
          </div>
        </div>
        <div>
          <p>Hello World</p>
          <div style="background: white; width: 600px">
            <lottie-vue-player
              style="background: red"
              :src="`https://assets9.lottiefiles.com/packages/lf20_cqdzv4dr.json`"
            >
            </lottie-vue-player>
          </div>
        </div>
      </div>
    </panZoom>
  </div>
</template>

<script>
import panZoom from "vue-panzoom";

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "App",
  data() {
    return {
      options: {
        minimizable: false,
        playerSize: "standard",
        backgroundColor: "#fff",
        backgroundStyle: "color",
      },
    };
  },
  created() {
    this.onRun();
  },
  methods: {
    onRun() {
      panZoom(document.getElementById("g4"), {
        beforeMouseDown: function (e) {
          // allow mouse-down panning only if altKey is down. Otherwise - ignore
          var shouldIgnore = !e.altKey;
          return shouldIgnore;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  background: #e9edf1;
}
</style>
