<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <LayersPanel class="layers-panel" />
    <LayersPanel class="properties-panel" />
    <router-view />
  </div>
</template>
<script>
import LayersPanel from "./components/LayersPanel.vue";
export default {
  components: {
    LayersPanel,
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #e9edf1;
  margin: 0;
  width: 100vw;
  overflow: hidden;
}

.lottie-player {
  background: none !important;
}

#nav {
  padding: 30px;
  z-index: 1000;
  position: relative;
  background: rgba(233, 237, 241, 0.2);
  backdrop-filter: blur(16px);
  width: 100%;
  overflow: hidden;
  top: 0;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.layers-panel {
  position: absolute;
  left: 0;
  z-index: 300;
}
.properties-panel {
  position: absolute;
  right: 0;
  z-index: 300;
}
</style>
